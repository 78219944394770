import React from 'react';
import { Box, Grid, Skeleton } from '@mui/material';

export default function ProductItemSkeleton({ viewMode }) {
    return (
        <Box
            sx={{
                borderRadius: '10px',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                <Grid item xs={viewMode === 'list' ? 4 : 12}>
                    <Skeleton variant="rectangular" width="100%" height={0} sx={{ paddingTop: '75%', borderRadius: '10px 10px 0 0' }} />
                </Grid>
                <Grid item xs={viewMode === 'list' ? 8 : 12} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box p={2} display="flex" flexDirection="column" height="100%">
                        <Skeleton
                            variant="text"
                            width="100%"
                            height={viewMode === 'list' ? 60 : 45}
                            sx={{ marginBottom: 2 }}
                        />

                        <Box sx={{ flexGrow: 1, overflow: 'auto', marginBottom: 2 }}>
                            <Grid container>
                                <Grid item xs={12}>
                                    {[...Array(4)].map((_, index) => (
                                        <Skeleton key={index} variant="text" width="90%" height={20} sx={{ marginBottom: 1 }} />
                                    ))}
                                </Grid>
                            </Grid>
                        </Box>

                        <Box
                            display="flex"
                            flexDirection={viewMode === 'list' ? 'row' : 'column'}
                            justifyContent={viewMode === 'list' ? 'space-between' : 'flex-end'}
                            alignItems="flex-start"
                            gap={1}
                            mt="auto"
                            width="100%"
                        >
                            <Skeleton variant="text" width={100} height={24} />
                            <Skeleton variant="rectangular" width={80} height={24} sx={{ borderRadius: '16px' }} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}