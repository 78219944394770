import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#1E88E5', // A more vibrant blue
            dark: '#1565C0', // Darker shade of primary
        },
        secondary: {
            main: '#ae8d27', //
            dark: '#C2185B', //
        },
        background: {
            default: '#F7F8FA',
            paper: '#FFFFFF',
        },
        icon: {
            main: '#fbce20',
            default: '#000000',
        }
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
});


export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#1d1d1d', // Light purple for readability on dark
            dark: '#3700B3', // Darker purple
        },
        secondary: {
            main: '#03DAC5', // Bright teal for contrast
            dark: '#018786', // Darker teal
        },
        background: {
            default: '#121212', // True dark mode background
            paper: '#1D1D1D', // Slightly lighter for contrast
        },
        icon: {
            main: '#fbce20',
            default: '#000000',
        }
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
});

export const defaultTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#D84315',
            dark: '#42A5F5',
        },
        secondary: {
            main: '#ffffff',
            dark: '#000',
        },
        background: {
            default: '#E0F7FA',
            paper: '#1f2431',
        },
        icon: {
            main: '#fbce20',
            default: '#000000',
        }
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
});

