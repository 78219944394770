import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import b1 from '../../../assets/img/b1.jpg';
import b2 from '../../../assets/img/b2.jpg';
import b3 from '../../../assets/img/b3.jpg';
import b4 from '../../../assets/img/b4.png';
import b5 from '../../../assets/img/b5.jpg';

const CarouselContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    maxWidth: '100%',
    margin: '0 auto',
    overflow: 'hidden',
    borderRadius: theme.shape.borderRadius,
}));

const SlideContainer = styled(Box)({
    display: 'flex',
    transition: 'transform 500ms ease-in-out',
});

const Slide = styled(Box)({
    position: 'relative',
    flexShrink: 0,
    width: '100%',
});

const SlideImage = styled('img')({
    width: '100%',
    height: 'auto',
    display: 'block',
});

const OverlayContent = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: theme.spacing(4),
    background: 'linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.4) 50%, rgba(0,0,0,0) 100%)',
    color: 'white',
}));

const OverlayTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
}));

const OverlayDescription = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
}));

const ProgressBar = styled(Box)(({ theme }) => ({
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 4,
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
}));

const ProgressIndicator = styled(Box)(({ theme }) => ({
    height: '100%',
    backgroundColor: 'red',
    transition: 'width 500ms linear',
}));

const NavButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    color: 'white',
    '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
}));

const Carousel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const images = [
        {
            url: b1,
            title: 'Modern Workspace',
            description: 'Boost your productivity with our ergonomic office solutions.',
        },
        {
            url: b2,
            title: 'Premium Audio',
            description: 'Immerse yourself in crystal-clear sound with our headphones.',
        },
        {
            url: b3,
            title: 'Smart Home Devices',
            description: 'Transform your living space with cutting-edge smart technology.',
        },
        {
            url: b4,
            title: 'Gaming Paradise',
            description: 'Level up your gaming experience with our high-performance gear.',
        },
        {
            url: b5,
            title: 'Fitness Tech',
            description: 'Track your progress and achieve your fitness goals with smart wearables.',
        },
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 5000);

        return () => clearInterval(interval);
    }, [images.length]);

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    return (
        <CarouselContainer>
            <SlideContainer style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                {images.map((image, index) => (
                    <Slide key={index}>
                        <SlideImage src={image.url} alt={`Slide ${index + 1}`} />
                        {/*<OverlayContent>*/}
                        {/*    <Container>*/}
                        {/*        <OverlayTitle variant="h3">{image.title}</OverlayTitle>*/}
                        {/*        <OverlayDescription variant="h6">{image.description}</OverlayDescription>*/}
                        {/*        <Button variant="contained" color="primary" size="large" sx={{ alignSelf: 'flex-start' }}>*/}
                        {/*            Shop Now*/}
                        {/*        </Button>*/}
                        {/*    </Container>*/}
                        {/*</OverlayContent>*/}
                    </Slide>
                ))}
            </SlideContainer>

            <NavButton onClick={handlePrev} sx={{ left: (theme) => theme.spacing(2) }}>
                <ArrowBackIosNewIcon />
            </NavButton>
            <NavButton onClick={handleNext} sx={{ right: (theme) => theme.spacing(2) }}>
                <ArrowForwardIosIcon />
            </NavButton>
            <ProgressBar>
                <ProgressIndicator
                    style={{ width: `${((currentIndex + 1) / images.length) * 100}%` }}
                />
            </ProgressBar>
        </CarouselContainer>
    );
};

export default Carousel;