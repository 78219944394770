import React, {useCallback, useEffect, useState, useRef} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {
    Box,
    Typography,
    TextField,
    List,
    ListItem,
    ListItemText,
    Grid,
    Divider,
} from '@mui/material';
import CategorySkeleton from '../CategorySkeleton';
import {useTheme} from "../../../Provider/ThemeProvider";

const DEFAULT_CATEGORY_ID = 6;

export default function Component({
                                      dataCategory,
                                      isCategoryLoading,
                                      errorCategory,
                                      dataSubCategory,
                                      isSubCategoryLoading,
                                      errorSubCategory,
                                      selectedFilters,
                                      suggestions,
                                      handleCategorySelect,
                                      handleFilterChange,
                                      handleSuggestionClick,
                                      handleSubCategorySelect,
                                      category_Id,
                                      items,
                                  }) {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState({});
    const [expandedCategory, setExpandedCategory] = useState(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);

    const urlCategoryId = searchParams.get('category') ? Number(searchParams.get('category')) : DEFAULT_CATEGORY_ID;
    const urlSubCategoryId = searchParams.get('subcategory') ? Number(searchParams.get('subcategory')) : null;

    const [categoryCounts, setCategoryCounts] = useState({});
    const [subCategoryCounts, setSubCategoryCounts] = useState({});
    const { currentTheme, setTheme } = useTheme()

    const initialSubCategoryCounts = useRef({});

    useEffect(() => {
        const countItems = () => {
            const catCounts = {};
            const subCatCounts = {};

            items.forEach(item => {
                if (item.current_stock > 0) {
                    catCounts[item.category] = (catCounts[item.category] || 0) + 1;
                    subCatCounts[item.sub_category] = (subCatCounts[item.sub_category] || 0) + 1;
                }
            });

            setCategoryCounts(catCounts);
            setSubCategoryCounts(subCatCounts);

            if (Object.keys(initialSubCategoryCounts.current).length === 0) {
                initialSubCategoryCounts.current = subCatCounts;
            }
        };

        countItems();
    }, [items]);

    useEffect(() => {
        if (urlCategoryId) {
            setExpandedCategory(urlCategoryId);
            handleCategorySelect(urlCategoryId);
        }
        if (urlSubCategoryId) {
            setSelectedSubCategory(urlSubCategoryId);
            handleSubCategorySelect(urlSubCategoryId);
        }
    }, [urlCategoryId, urlSubCategoryId, handleCategorySelect, handleSubCategorySelect]);

    useEffect(() => {
        if (dataCategory && dataCategory.length > 0) {
            if (urlCategoryId) {
                const selectedCategory = dataCategory.find(cat => cat.id === urlCategoryId);
                if (selectedCategory) {
                    setCategories([selectedCategory]);
                    setExpandedCategory(urlCategoryId);
                } else {
                    setCategories(dataCategory);
                }
            } else {
                setCategories(dataCategory);
            }

            if (urlSubCategoryId && dataSubCategory && dataSubCategory.length > 0) {
                const subCategoryExists = dataSubCategory.some(subCat => subCat.id === urlSubCategoryId);
                if (subCategoryExists) {
                    setSelectedSubCategory(urlSubCategoryId);
                    handleSubCategorySelect(urlSubCategoryId);
                }
            }
        }
    }, [dataCategory, dataSubCategory, urlCategoryId, urlSubCategoryId, handleSubCategorySelect]);


    useEffect(() => {
        if (dataSubCategory && dataSubCategory.length > 0) {
            const subCategoriesMap = {};
            dataSubCategory.forEach(subCat => {
                if (!subCategoriesMap[subCat.item_category]) {
                    subCategoriesMap[subCat.item_category] = [];
                }
                subCategoriesMap[subCat.item_category].push(subCat);
            });
            setSubCategories(subCategoriesMap);
        }
    }, [dataSubCategory]);

    const handleCategoryClick = useCallback((clickedCategoryId) => {
        setExpandedCategory(prevExpanded => prevExpanded === clickedCategoryId ? null : clickedCategoryId);
        handleCategorySelect(clickedCategoryId);
        setSelectedSubCategory(null);
        handleSubCategorySelect(null);
        searchParams.set('category', clickedCategoryId.toString());
        searchParams.delete('subcategory');
        navigate(`${location.pathname}?${searchParams.toString()}`, {replace: true});
    }, [handleCategorySelect, navigate, location.pathname, searchParams]);

    const handleSubCategoryClick = useCallback((categoryId, subCatId, event) => {
        event.stopPropagation();
        const newSelectedSubCategory = selectedSubCategory === subCatId ? null : subCatId;
        setSelectedSubCategory(newSelectedSubCategory);
        handleSubCategorySelect(newSelectedSubCategory);

        searchParams.set('category', categoryId.toString());
        if (newSelectedSubCategory === null) {
            searchParams.delete('subcategory');
        } else {
            searchParams.set('subcategory', subCatId.toString());
        }
        navigate(`${location.pathname}?${searchParams.toString()}`, {replace: true});
    }, [handleSubCategorySelect, navigate, location.pathname, searchParams, selectedSubCategory]);
    return (
        <Box
            p={2}
            borderRadius={1}
            sx={{
                backgroundColor: (theme) =>
                    currentTheme === 'dark' ? theme.palette.background.paper : 'transparent',
                boxShadow:
                    currentTheme === 'dark'
                        ? '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)'
                        : 'none',
            }}
        >
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', textTransform: 'uppercase' , fontSize: '18px', }}>
                Filters
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: '14px', }}>
                        Search
                    </Typography>
                    <TextField
                        fullWidth
                        type="search"
                        placeholder="Search products..."
                        value={selectedFilters.search}
                        onChange={(e) => handleFilterChange("search", e.target.value)}
                    />
                    {suggestions.length > 0 && (
                        <List>
                            {suggestions.map((suggestion, index) => (
                                <ListItem
                                    key={index}
                                    button
                                    onClick={() => handleSuggestionClick(suggestion)}
                                >
                                    <ListItemText
                                        primaryTypographyProps={{
                                            sx: {
                                                fontSize: '14px',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }
                                        }}
                                        primary={suggestion}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
                        {urlCategoryId ? 'Subcategories' : 'Categories'}
                    </Typography>
                    {isCategoryLoading ? (
                        <CategorySkeleton />
                    ) : errorCategory ? (
                        <Typography color="error">Error loading categories</Typography>
                    ) : categories.length === 0 ? (
                        <Typography>No categories available</Typography>
                    ) : (
                        <List>
                            {categories.map((category, index) => (
                                <React.Fragment key={category.id}>
                                    <ListItem
                                        button
                                        onClick={() => handleCategoryClick(category.id)}
                                        selected={expandedCategory === category.id}
                                    >
                                        <ListItemText
                                            primaryTypographyProps={{
                                                sx: {
                                                    fontSize: '14px',
                                                    textTransform: 'capitalize',
                                                }
                                            }}
                                            primary={category.desc}
                                        />
                                    </ListItem>
                                    {expandedCategory === category.id && (
                                        <>
                                            {isSubCategoryLoading ? (
                                                <CategorySkeleton />
                                            ) : errorSubCategory ? (
                                                <Typography color="error">Error loading subcategories</Typography>
                                            ) : !subCategories[category.id] || subCategories[category.id].length === 0 ? (
                                                <Typography>No subcategories available</Typography>
                                            ) : (
                                                <List component="div" disablePadding>
                                                    {subCategories[category.id]
                                                        .filter(subCat => (subCategoryCounts[subCat.id] || initialSubCategoryCounts.current[subCat.id] || 0) > 0)
                                                        .map((subCat, subIndex) => (
                                                            <React.Fragment key={subCat.id}>
                                                                <ListItem
                                                                    button
                                                                    onClick={(event) => handleSubCategoryClick(category.id, subCat.id, event)}
                                                                    selected={selectedSubCategory === subCat.id}
                                                                >
                                                                    <ListItemText
                                                                        primary={`${subCat.desc} (${subCategoryCounts[subCat.id] || initialSubCategoryCounts.current[subCat.id] || 0})`}
                                                                        primaryTypographyProps={{
                                                                            sx: {
                                                                                fontSize: '13px',
                                                                            }
                                                                        }}
                                                                    />
                                                                </ListItem>
                                                                {subIndex < subCategories[category.id].length - 1 && <Divider />}
                                                            </React.Fragment>
                                                        ))}
                                                </List>
                                            )}
                                        </>
                                    )}
                                    {index < categories.length - 1 && <Divider />}
                                </React.Fragment>
                            ))}
                        </List>
                    )}
                </Grid>
            </Grid>
        </Box>

    );
}