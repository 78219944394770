import { useQuery } from '@tanstack/react-query';
import client from '../services/client';

export const useGetGenerations = () => {
    return useQuery({
        queryKey: ['generations'],
        queryFn: async () => {
            try {
                const response = await client.get('/generations');
                let data = response.data;
                if (data.categories) {
                    data = data.categories;
                }
                return data;
            } catch (error) {
                console.error('Error fetching categories:', error);
                throw new Error('Error fetching categories: ' + (error.response?.data?.message || error.message));
            }
        },
        staleTime: 1000 * 60 * 5,
    });
};