// src/pages/Home.js
import React from 'react';
import HomePage from '../../components/Home/home_page';
import {useGetManufacturersWithItems} from "../../hooks/useManufactureItemHook";

function Home() {
    const { data: dataManufacturersWithItems, isLoading: isManufacturersWithItemsLoading, error: ManufacturersWithItemsError } = useGetManufacturersWithItems();
    return (

        <HomePage items={dataManufacturersWithItems} isLoading={isManufacturersWithItemsLoading} error={ManufacturersWithItemsError} />
    );
}

export default Home;
