import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ThemeProvider from './Provider/ThemeProvider';
import QueryProvider from "./Provider/QueryProvider";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
            <QueryProvider>
                <ThemeProvider>
                    <App />
                </ThemeProvider>
            </QueryProvider>
    </React.StrictMode>
);

reportWebVitals();