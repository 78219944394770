import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Navbar from './components/common/navbar';
import Routes from './routes/routes';
import Footer from "./components/common/footer";
import ScrollToTop from './helpers/ScrollToTop';


function App() {

    return (

        <Router>
            <ScrollToTop />
            <Navbar />
            <main>
                <Routes />
            </main>
            <Footer />
        </Router>
    );
}

export default App;