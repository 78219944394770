import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import 'owl.carousel/dist/assets/owl.carousel.css'
import {
    Button,
    Card,
    CardContent,
    Typography,
    CssBaseline,
    Box,
    IconButton,
    useTheme,
    Radio,
    RadioGroup,
    useMediaQuery,
} from "@mui/material"
import { styled } from '@mui/system'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

const CarouselContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    padding: theme.spacing(4, 2),
    '&:hover .nav-button': {
        opacity: 1,
    },
}))

const NavButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 2,
    backgroundColor: theme.palette.action.active,
    color: theme.palette.common.white,
    opacity: 0,
    transition: 'opacity 0.3s ease',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
    '&.left': {
        left: theme.spacing(1),
    },
    '&.right': {
        right: theme.spacing(1),
    },
}))

const ProductImage = styled('img')({
    width: '100%',
    objectFit: 'cover',
    aspectRatio: 4 / 3,
    borderRadius: '10px 10px 0px 0px'
})

const ImageRadioGroup = styled(RadioGroup)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
}))

const ProductCard = React.memo(({ product, selectedImageIndex, onImageChange, onProductClick }) => {
    const [, forceUpdate] = React.useReducer(x => x + 1, 0)

    useEffect(() => {
        product.images.forEach((imagePath) => {
            const img = new Image()
            img.src = `https://posnew.conixsystem.com/${imagePath}`
        })
    }, [product.images])

    const handleRadioChange = (event) => {
        event.preventDefault()
        event.stopPropagation()
        const newIndex = Number(event.target.value)
        onImageChange(product.id, newIndex)
        forceUpdate()
    }

    const formattedPrice = new Intl.NumberFormat('en-PK', {
        style: 'currency',
        currency: 'PKR',
        minimumFractionDigits: 2,
    }).format(product.sale_price)

    return (
        <Card
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                cursor: 'pointer',
                backgroundColor: 'transparent',
                boxShadow: 'none',
                borderRadius: '10px',
                '&:hover': {
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                    backgroundColor: 'white',
                    color: 'black',
                }
            }}
            onClick={() => onProductClick(product)}
        >
            <ProductImage
                src={`https://posnew.conixsystem.com/${product.images[selectedImageIndex]}`}
                alt={product.name}
                loading="lazy"
            />
            {product.images.length > 1 && (
                <ImageRadioGroup
                    row
                    value={selectedImageIndex}
                    onChange={handleRadioChange}
                    onClick={(e) => e.stopPropagation()}
                >
                    {product.images.map((_, imgIndex) => (
                        <Radio
                            key={imgIndex}
                            value={imgIndex}
                            size="small"
                            sx={{ padding: '4px' }}
                        />
                    ))}
                </ImageRadioGroup>
            )}
            <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Typography
                    gutterBottom
                    sx={{
                        // color: '#333',
                        textDecoration: 'none',
                        fontWeight: '500',
                        fontSize: '14px',
                        height: '2.8em',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                    }}
                >
                    {product.name}
                </Typography>

                <Box>
                    <Typography variant="body1" color="text.secondary">
                        {product.remarks && product.remarks.length > 0 ? (
                            <ul
                                style={{
                                    listStyleType: 'disc',
                                    paddingLeft: '25px',
                                    fontSize: '12px',
                                    color: '#8C8C8C',
                                    fontWeight: 400,
                                    margin: '18px 0px',
                                    height: '6em',
                                    overflow: 'hidden',
                                }}
                            >
                                {product.remarks.slice(0, 4).map((remark, index) => (
                                    <li key={index}>{remark}</li>
                                ))}
                            </ul>
                        ) : (
                            'No description available.'
                        )}
                    </Typography>

                    <Typography variant="body1" mt={2} fontWeight="bold" sx={{ mb: 1, color: '#0069a6', fontSize: '17px' }}>
                        {product.sale_price ? `${formattedPrice}` : 'Price not available'}
                    </Typography>

                    <Button variant="outlined" fullWidth onClick={(e) => {
                        e.stopPropagation()
                        onProductClick(product)
                    }}>
                        View Details
                    </Button>
                </Box>
            </CardContent>
        </Card>
    )
})

const ProductCarousel = ({ title, products }) => {
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const [key, setKey] = useState(0)
    const [selectedImages, setSelectedImages] = useState({})
    const carouselRef = useRef(null)
    const navigate = useNavigate()
    const isDragging = useRef(false)

    useEffect(() => {
        setKey(prevKey => prevKey + 1)
        const initialSelectedImages = products.reduce((acc, product) => {
            acc[product.id] = 0
            return acc
        }, {})
        setSelectedImages(initialSelectedImages)
    }, [products])

    const handlePrev = () => {
        if (carouselRef.current) {
            carouselRef.current.slickPrev()
        }
    }

    const handleNext = () => {
        if (carouselRef.current) {
            carouselRef.current.slickNext()
        }
    }

    const handleImageChange = useCallback((productId, imageIndex) => {
        setSelectedImages(prev => ({
            ...prev,
            [productId]: imageIndex,
        }))
    }, [])

    const handleProductClick = useCallback((product) => {
        if (isDragging.current) return
        navigate(`${encodeURIComponent(product.name)}`, { state: { item: product } })
    }, [navigate])

    const StyledTypography = styled(Typography)(({ theme }) => ({
        marginBottom: theme.spacing(4),
        textTransform: 'uppercase',
        textAlign: 'left',
        fontFamily: "'PT Serif', serif",
        backgroundColor: 'transparent',
        fontWeight: 700,
        fontSize: '30px',
        position: 'relative',
        '&:after': {
            background: '#DDDDDD',
            content: '""',
            display: 'block',
            height: '2px',
            marginLeft: 0,
            position: 'absolute',
            top: '43px',
            width: '100%',
            zIndex: -1,
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '24px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
            marginBottom: theme.spacing(2),
        },
    }))

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            { breakpoint: 1200, settings: { slidesToShow: 4, slidesToScroll: 4 } },
            { breakpoint: 960, settings: { slidesToShow: 3, slidesToScroll: 3 } },
            { breakpoint: 600, settings: { slidesToShow: 2, slidesToScroll: 2 } },
            { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
        ],
        arrows: false,
    }

    const handleMouseUp = () => {
        setTimeout(() => {
            isDragging.current = false
        }, 100)
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <CarouselContainer>
                <StyledTypography
                    variant={isSmallScreen ? 'h2' : 'h3'}
                    component="h3"
                    gutterBottom
                >
                    {title}
                </StyledTypography>
                <Slider
                    {...settings}
                    ref={carouselRef}
                    onMouseDown={() => { isDragging.current = true }}
                    onMouseUp={handleMouseUp}
                    onTouchStart={() => { isDragging.current = true }}
                    onTouchEnd={handleMouseUp}
                >
                    {products.map((product) => (
                        <Box key={product.id} sx={{ px: 1, height: '100%' }}>
                            <ProductCard
                                product={product}
                                selectedImageIndex={selectedImages[product.id] || 0}
                                onImageChange={handleImageChange}
                                onProductClick={handleProductClick}
                            />
                        </Box>
                    ))}
                </Slider>
                <NavButton className="nav-button left" onClick={handlePrev} aria-label="Previous product">
                    <ChevronLeftIcon />
                </NavButton>
                <NavButton className="nav-button right" onClick={handleNext} aria-label="Next product">
                    <ChevronRightIcon />
                </NavButton>
            </CarouselContainer>
        </React.Fragment>
    )
}

export default React.memo(ProductCarousel)