import { useQuery } from '@tanstack/react-query';
import client from '../services/client';

// Fetching items with optional category and subcategory filters
const fetchItems = async (jsonData = {}) => {
    const queryParams = new URLSearchParams();

    if (jsonData.category_ids) {
        jsonData.category_ids.forEach((id) => queryParams.append('category_ids[]', id));
    }

    if (jsonData.sub_category_ids) {
        jsonData.sub_category_ids.forEach((id) => queryParams.append('sub_category_ids[]', id));
    }

    // Make the GET request with query parameters
    const response = await client.get(`/items?${queryParams.toString()}`);

    if (!response) {
        throw new Error('Failed to fetch items');
    }
    return response.data.Items;
};

export const useFetchItems = (jsonData = {}) => {
    return useQuery({
        queryKey: ['items', jsonData],
        queryFn: () => fetchItems(jsonData),
        keepPreviousData: false,
    });
};
