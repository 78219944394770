import React from 'react';
import {Link} from 'react-router-dom';
import {
    AppBar,
    Toolbar,
    Box,
    Button,
    Container,
    Grid,
    Typography,
    Divider
} from "@mui/material";
import {WhatsApp as WhatsAppIcon} from '@mui/icons-material';
import {useTheme} from '@mui/material/styles';
import logo from "../../assets/img/logo.png";

function Footer() {
    const theme = useTheme();
    return (
        <AppBar position="static" color="default"
                sx={{backgroundImage: 'none', backgroundColor: `${theme.palette.background.paper} !important`}}
                elevation={0} component="footer">
            <Container maxWidth="lg">
                <Toolbar disableGutters sx={{py: {xs: 6, md: 8, lg: 10}}}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6} lg={3}>
                            <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                                <Link to="/" style={{
                                    textDecoration: 'none',
                                    color: 'inherit',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1
                                }}>
                                    <Box component={Link} to="/" sx={{
                                        flexGrow: {xs: 1, md: 0},
                                        display: 'flex',
                                        alignItems: 'center',
                                        textDecoration: 'none'
                                    }}>
                                        <img
                                            src={logo}
                                            alt="Conix Logo"
                                            style={{height: '40px', width: 'auto'}}
                                        />
                                    </Box>
                                </Link>
                                <Typography variant="body2" sx={{
                                    color: theme.palette.secondary.main
                                }}>
                                    Discover the best laptops for your needs.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Typography variant="h6" gutterBottom sx={{
                                color: theme.palette.secondary.main
                            }}>Quick Links</Typography>
                            <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
                                <Link to="/" style={{
                                    textDecoration: 'none',
                                    color: theme.palette.secondary.main,
                                }}>Home</Link>
                                <Link to="/laptops" style={{
                                    textDecoration: 'none',
                                    color: theme.palette.secondary.main,
                                }}>Laptops</Link>
                                <Link to="/contact" style={{
                                    textDecoration: 'none',
                                    color: theme.palette.secondary.main,
                                }}>Contact Us</Link>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Typography variant="h6" gutterBottom sx={{
                                color: theme.palette.secondary.main
                            }}>Categories</Typography>
                            <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
                                <Link to="/laptops" style={{
                                    textDecoration: 'none',
                                    color: theme.palette.secondary.main,
                                }}>Browse Categories</Link>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Typography variant="h6" gutterBottom sx={{
                                color: theme.palette.secondary.main
                            }}>Contact Us on WhatsApp</Typography>
                            <Typography variant="body2" sx={{
                                color: theme.palette.secondary.main
                            }} gutterBottom>
                                Get in touch with us instantly via WhatsApp for quick support and inquiries.
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<WhatsAppIcon/>}
                                href="https://wa.me/+923254290003"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Join Our WhatsApp
                            </Button>
                        </Grid>
                    </Grid>
                </Toolbar>
                <Divider/>
                <Toolbar disableGutters sx={{
                    py: 2,
                    flexDirection: {xs: 'column', md: 'row'},
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 2
                }}>
                    <Typography variant="body2" color="text.secondary">
                        &copy; 2024 Conix Store. All rights reserved.
                    </Typography>
                    <Box sx={{display: 'flex', gap: 2}}>
                        <Link to="/terms" sx={{
                            color: theme.palette.secondary.main
                        }}>
                            Terms of Service
                        </Link>
                        <Link to="/privacy" sx={{
                            color: theme.palette.secondary.main
                        }}>
                            Privacy Policy
                        </Link>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Footer;