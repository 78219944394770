import React, { useState, useEffect, useCallback } from 'react'
import { Box, Typography, Chip, Grid, useMediaQuery, useTheme, Radio, RadioGroup } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ReactGA from 'react-ga4'
import { Smartphone, Tablet, Monitor } from 'lucide-react'

// Initialize Google Analytics
ReactGA.initialize('G-DQ6C50VLKX')

// ProductItem Component
export default function ProductItem({ item, viewMode }) {
    const [selectedImageIndex, setSelectedImageIndex] = useState(0)
    const [preloadedImages, setPreloadedImages] = useState([])
    const theme = useTheme()
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))
    const isMdSm = useMediaQuery(theme.breakpoints.between('sm', 'lg'))
    const navigate = useNavigate()

    useEffect(() => {
        const preloadImages = async () => {
            const loadedImages = await Promise.all(
                item.images.map((src) => {
                    return new Promise((resolve, reject) => {
                        const img = new Image()
                        img.src = `https://posnew.conixsystem.com/${src}`
                        img.onload = () => resolve(img.src)
                        img.onerror = reject
                    })
                })
            )
            setPreloadedImages(loadedImages)
        }

        preloadImages()

        // Track product impression
        ReactGA.event({
            category: 'Product',
            action: 'Impression',
            label: item.name,
        })
    }, [item.images, item.name])

    const formattedPrice = new Intl.NumberFormat('en-PK', {
        style: 'currency',
        currency: 'PKR',
        minimumFractionDigits: 2,
    }).format(item.sale_price)

    const handleImageChange = (event) => {
        event.preventDefault()
        event.stopPropagation()
        setSelectedImageIndex(Number(event.target.value))

        ReactGA.event({
            category: 'Product',
            action: 'Image Change',
            label: item.name,
            value: Number(event.target.value),
        })
    }

    const handleProductClick = useCallback((item) => {
        // Track product click
        ReactGA.event({
            category: 'Product',
            action: 'Click',
            label: item.name,
        })

        // Pass userCounts to the product page
        navigate(`/${encodeURIComponent(item.name)}`, {
            state: {
                item,
                referrer: document.referrer,
            }
        })
    }, [navigate])

    return (
        <Box
            onClick={(e) => {
                const isRadioClick = e.target.closest('.MuiRadio-root')
                if (!isRadioClick) {
                    handleProductClick(item)
                }
            }}
            overflow="hidden"
            sx={{
                cursor: 'pointer',
                borderRadius: '10px',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                    backgroundColor: 'white',
                    color: 'black',
                },
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                <Grid item xs={viewMode === 'list' ? 4 : 12}>
                    <Box position="relative">
                        {preloadedImages[selectedImageIndex] && (
                            <img
                                src={preloadedImages[selectedImageIndex]}
                                alt={item.name}
                                width="100%"
                                height="auto"
                                style={{ aspectRatio: '4/3', objectFit: 'unset', borderRadius: '10px 10px 0 0' }}
                            />
                        )}
                        {item.images.length > 1 && (
                            <RadioGroup
                                row
                                value={selectedImageIndex.toString()}
                                onChange={handleImageChange}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: theme.spacing(1),
                                }}
                            >
                                {item.images.map((_, index) => (
                                    <Radio
                                        key={index}
                                        value={index.toString()}
                                        size="small"
                                        sx={{
                                            padding: '4px'
                                        }}
                                    />
                                ))}
                            </RadioGroup>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={viewMode === 'list' ? 8 : 12} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box p={2} display="flex" flexDirection="column" height="100%">
                        <Typography
                            variant={viewMode === 'list' ? 'h5' : 'h6'}
                            sx={{
                                wordWrap: 'break-word',
                                overflowWrap: 'break-word',
                                hyphens: 'auto',
                                maxWidth: '100%',
                                lineHeight: viewMode === 'list' ? '1.2' : '17px',
                                fontWeight: 500,
                                textDecoration: 'none',
                                fontSize: viewMode === 'list' ? { xs: '18px', sm: '20px', md: '22px' } : '14px',
                                marginBottom: 2,
                                minHeight: viewMode === 'list' ? '2.4em' : '3.4em',
                                display: '-webkit-box',
                                WebkitLineClamp: viewMode === 'list' ? 2 : 3,
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                            }}
                        >
                            {item.name}
                        </Typography>

                        {item.remarks && item.remarks.length > 0 && (
                            <Box sx={{ flexGrow: 1, overflow: 'auto', marginBottom: 2 }}>
                                <Grid container>
                                    {viewMode === 'list' ? (
                                        <Grid item xs={12}>
                                            <ul
                                                style={{
                                                    listStyleType: 'disc',
                                                    paddingLeft: '16px',
                                                    fontSize: '12px',
                                                    color: '#8C8C8C',
                                                    fontWeight: 400,
                                                    margin: '0',
                                                }}
                                            >
                                                {(() => {
                                                    if (isLgUp) {
                                                        return item.remarks.map((remark, index) => (
                                                            <li key={index}>{remark}</li>
                                                        ))
                                                    } else if (isMdSm) {
                                                        return item.remarks.slice(0, 4).map((remark, index) => (
                                                            <li key={index}>{remark}</li>
                                                        ))
                                                    }
                                                    return null
                                                })()}
                                            </ul>
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12}>
                                            <ul
                                                style={{
                                                    listStyleType: 'disc',
                                                    paddingLeft: '16px',
                                                    fontSize: '12px',
                                                    color: '#8C8C8C',
                                                    fontWeight: 400,
                                                    margin: '0',
                                                }}
                                            >
                                                {item.remarks.slice(0, 4).map((remark, index) => (
                                                    <li key={index}>{remark}</li>
                                                ))}
                                            </ul>
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                        )}

                        <Box
                            display="flex"
                            flexDirection={viewMode === 'list' ? 'row' : 'column'}
                            justifyContent={viewMode === 'list' ? 'space-between' : 'flex-end'}
                            alignItems="flex-start"
                            gap={1}
                            mt="auto"
                            width="100%"
                        >
                            <Typography
                                variant="body1"
                                fontWeight="bold"
                                sx={{
                                    fontSize: '15px',
                                    fontWeight: 500,
                                }}
                            >
                                {formattedPrice}
                            </Typography>
                            <Chip
                                label={item.current_stock > 0 ? "In Stock" : "Out of Stock"}
                                color={item.current_stock > 0 ? "success" : "error"}
                                size="small"
                                sx={{
                                    maxWidth: '100%',
                                    '& .MuiChip-label': {
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }
                                }}
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
