import { useQuery } from '@tanstack/react-query';
import client from '../services/client';


const fetchSubCategory = async (jsonData = {}) => {
    const queryParams = new URLSearchParams();

    if (jsonData.category_ids) {
        jsonData.category_ids.forEach((id) => queryParams.append('category_ids[]', id));
    }
    const response = await client.get(`/subcategories?${queryParams.toString()}`);
    if (!response) {
        throw new Error('Failed to fetch items');
    }
    return response.data.subCategories;
}


export const useGetSubCategory = (jsonData = {}) => {
    return useQuery({
        queryKey: ['subcategories', jsonData],
        queryFn: () => fetchSubCategory(jsonData),
        keepPreviousData: false,
    });
};
