import React, { useEffect, useState, useCallback } from 'react';
import ProductCarousel from '../Home/Products/product';
import Carosuel from "./Carosuel/carosuel";
import {Container} from "@mui/material";
import Loader from "../common/loader";

const Home_Page = ({ items, isLoading, error }) => {

    return (
        <section>
            <Carosuel />
            <Container>
                {items?.Manufacturers?.length > 0 ? (
                    items.Manufacturers.map((manufacturer) => (
                        <ProductCarousel
                            key={manufacturer.manufacturer_id}
                            title={manufacturer.manufacturer_name}
                            products={Object.values(manufacturer.items)}
                            isLoading={isLoading}
                        />
                    ))
                ) : (
                    <Loader />
                    )}
            </Container>
        </section>
    );
};


export default Home_Page;