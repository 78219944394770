import React from 'react';
import { Box, Skeleton } from '@mui/material';

export default function CategorySkeleton() {
    return (
        <Box>
            <Skeleton animation="wave" height={40} width="100%"/>
            <Skeleton animation="wave" height={40} width="100%" />
            <Skeleton animation="wave" height={40} width="100%"  />
        </Box>
    );
}