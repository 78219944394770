import axios from 'axios';

const client = axios.create({
    baseURL: 'https://posnew.conixsystem.com/api',
    withCredentials: false,
});

client.interceptors.request.use((config) => {
    config.headers = config.headers || {};
    return config;
});

client.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            console.error('Unauthorized access:', error);
        }
        return Promise.reject(error);
    }
);

export default client;