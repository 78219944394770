import React from 'react';
import {Box, Container, Grid, Paper, Typography, useTheme} from "@mui/material";
import {
    LocationOn as LocationOnIcon,
    Mail as MailIcon,
    Phone as PhoneIcon, Public as GlobeIcon,
    VoiceChat as VoicemailIcon
} from "@mui/icons-material";
import shopImage from '../../../assets/img/shop.jpeg';

function Index() {
    const theme = useTheme();

    const sectionStyle = {
        width: '100%',
        padding: theme.spacing(6, 0),
    };

    const darkSectionStyle = {
        ...sectionStyle,
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
    };

    return (
        <Box component="section" sx={darkSectionStyle}>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" gutterBottom>Contact Us</Typography>
                        <Box sx={{mb: 2}}>
                            {[
                                {icon: <LocationOnIcon/>, text: 'Shop 82 3rd floor Hafeez Center Lahore'},
                                {icon: <PhoneIcon/>, text: '03254290003'},
                                {icon: <MailIcon/>, text: 'conixsystem@gmail.com'},
                                {icon: <GlobeIcon/>, text: 'www.conixsystem.com'},
                            ].map((item, index) => (
                                <Box key={index} sx={{display: 'flex', alignItems: 'center', gap: 1, mb: 2}}>
                                    {React.cloneElement(item.icon, {color: 'primary'})}
                                    <Typography>{item.text}</Typography>
                                </Box>
                            ))}
                        </Box>
                        {/* Add shop image here */}
                        <Box sx={{ mt: 4 }}>
                            <img src={shopImage} alt="Shop" style={{ width: '100%', borderRadius: theme.shape.borderRadius }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" gutterBottom>Our Location</Typography>
                        <Paper sx={{overflow: 'hidden', borderRadius: theme.shape.borderRadius}}>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3401.2971495273496!2d74.34029067698576!3d31.51599767421602!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919056b55b3093d%3A0xadc3b4e4fd3132bf!2sCONIX%20SYSTEM!5e0!3m2!1sen!2s!4v1730579260412!5m2!1sen!2s"
                                    width="100%"
                                    height="450"
                                    style={{border: 0}}
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                    title="Lahore Township"
                            ></iframe>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default Index;