import React from 'react';

const LoaderAnimation = () => {
    return (
        <div className="flex justify-center items-center h-screen">
            <div className="relative w-24 h-24 transform rotate-45">
                {[...Array(7)].map((_, index) => (
                    <div
                        key={index}
                        className="absolute top-0 left-0 w-7 h-7 m-0.5 rounded-none bg-white bg-cover bg-center bg-fixed"
                        style={{
                            animation: 'square-animation 10s ease-in-out infinite both',
                            animationDelay: `${-1.4285714286 * index}s`,
                        }}
                    ></div>
                ))}
            </div>
            <style jsx>{`
        @keyframes square-animation {
          0%, 10.5% { left: 0; top: 0; }
          12.5%, 23% { left: 2rem; top: 0; }
          25%, 35.5% { left: 4rem; top: 0; }
          37.5%, 48% { left: 4rem; top: 2rem; }
          50%, 60.5% { left: 2rem; top: 2rem; }
          62.5%, 73% { left: 2rem; top: 4rem; }
          75%, 85.5% { left: 0; top: 4rem; }
          87.5%, 98% { left: 0; top: 2rem; }
          100% { left: 0; top: 0; }
        }
      `}</style>
        </div>
    );
};

export default LoaderAnimation;