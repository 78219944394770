import React from 'react';
import {Box, Button, Container, Grid, TextField, Typography, useTheme} from "@mui/material";

function Index() {
    const theme = useTheme();

    const sectionStyle = {
        width: '100%',
        padding: theme.spacing(6, 0),
    };

    const darkSectionStyle = {
        ...sectionStyle,
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
    };


    return (
        <Box component="section" sx={darkSectionStyle}>
            <Container>
                <Box sx={{mb: 3, textAlign: 'center'}}>
                    <Typography variant="h3" component="h1" gutterBottom>Get in Touch</Typography>
                    <Typography variant="body1" color="text.secondary" sx={{maxWidth: '600px', mx: 'auto'}}>
                        Have a question or need assistance? Fill out the form below and we'll get back to you as
                        soon as possible.
                    </Typography>
                </Box>
                <Box component="form" sx={{width: '100%', maxWidth: '600px', mx: 'auto'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField label="Name" id="name" placeholder="John Doe" fullWidth/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField label="Email" id="email" type="email" placeholder="m@example.com" fullWidth/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField label="Phone" id="phone" placeholder="+1 (123) 456-7890" fullWidth/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField label="Company" id="company" placeholder="Acme Inc." fullWidth/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label="Subject" id="subject" placeholder="Inquiry about your products"
                                       fullWidth/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label="Message" id="message" multiline rows={5}
                                       placeholder="How can we help you?" fullWidth/>
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" color="primary" fullWidth>Submit</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
}

export default Index;