import React from 'react';
import {Box} from '@mui/material';


import Services from "./Services";
import Form from "./Form";

export default function ContactPage() {

    return (
        <Box sx={{width: '100%'}}>
            <Form/>

            <Services/>


        </Box>
    );
}